import React from 'react'
import background from "../images/backgroundoriginal.png";

const Bathlinen = () => {
    const products = [
        {
            title: "SplendourLux Luxury Divan Collection",
            description: "Experience the perfect blend of style and functionality with our premium divan collection. Our divan beds are designed to maximize space efficiency, being exactly the same dimensions as your mattress while providing integrated storage solutions.",
            additionalInfo: "Each divan bed features high-quality construction with built-in drawers, offering dust-free storage space that's perfect for compact bedrooms. Complemented by our range of Bean Bags, Curtains, Bed Runners, and luxury Duvets.",
            features: [
                "Space-efficient design",
                "Integrated storage drawers",
                "Dust-protected storage",
                "Perfect for compact rooms",
                "Matching bed runners available",
                "Coordinated curtain options",
                "Comfortable bean bags",
                "Premium duvet covers",
                "Easy maintenance",
                "Sturdy construction",
                "Multiple size options",
                "Contemporary styling"
            ],
            specifications: [
                "Divan Base: Premium solid construction",
                "Storage: Built-in drawers with smooth runners",
                "Duvet: High-quality down filling options",
                "Duvet Cover: Double-layered fabric construction",
                "Bean Bags: Durable upholstery material",
                "Curtains: Coordinated design options"
            ],
            images: [
                "https://files.catbox.moe/8cy4d7.png",
                "https://files.catbox.moe/bxjks9.png",
                "https://files.catbox.moe/g8y1x5.png",
                "https://files.catbox.moe/c42yx4.png",
                "https://files.catbox.moe/e5flpr.png",
                
                "https://files.catbox.moe/ghm0r5.png",
            ]
        }
    ]

    return (
        <div style={{
            backgroundImage: `url(${background})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            position: 'relative',
            minHeight: '100vh',
        }}>
            <div style={{
                backdropFilter: 'blur(13px)',
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                padding: '2rem 0',
            }}>
                <div className='py-10 mx-auto px-4'>
                    <div className='px-4 md:px-8 lg:px-20'>
                    <h1 className='text-2xl md:text-3xl lg:text-4xl font-bold text-center mb-6 text-[#8B4513]'>Our Luxury Divan Collection</h1>
                    {products.map((product, index) => (
                        <div key={index} className='space-y-8 rounded-lg'>
                            <div className='space-y-8'>
                                <div className='w-full'>
                                    <img
                                        src={product.images[0]}
                                        alt={`${product.title} view 1`}
                                        className='w-full h-[250px] md:h-[300px] lg:h-[400px] object-contain rounded-lg'
                                    />
                                </div>

                                <div className='grid grid-cols-1 lg:grid-cols-2 gap-8'>
                                    <div className='space-y-6'>
                                        <h2 className='text-2xl md:text-3xl font-bold text-[#8B4513]'>{product.title}</h2>
                                        <p className='text-gray-700 text-sm md:text-base'>{product.description}</p>
                                        <p className='text-gray-700 text-sm md:text-base'>{product.additionalInfo}</p>
                                    </div>
                                    <img
                                        src={product.images[2]}
                                        alt={`${product.title} view 3`}
                                        className='w-full h-[250px] md:h-[300px] lg:h-[400px] object-contain rounded-lg'
                                    />
                                </div>

                                <div className='grid grid-cols-1 lg:grid-cols-3 gap-8'>
                                    <div className='space-y-4 lg:col-span-2'>
                                        <h3 className='text-lg md:text-xl font-semibold text-[#8B4513]'>Key Features</h3>
                                        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                                            {product.features.map((feature, idx) => (
                                                <div key={idx} className='flex items-center space-x-2'>
                                                    <span className='w-2 h-2 bg-[#DEB887] rounded-full'></span>
                                                    <span className='text-gray-700 text-sm md:text-base'>{feature}</span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <img
                                        src={product.images[3]}
                                        alt={`${product.title} view 4`}
                                        className='w-full h-[250px] md:h-[300px] object-contain rounded-lg'
                                    />
                                </div>

                                <div className='grid grid-cols-1 lg:grid-cols-3 gap-8'>
                                    <img
                                        src={product.images[1]}
                                        alt={`${product.title} view 5`}
                                        className='w-full h-[250px] md:h-[300px] object-contain rounded-lg'
                                    />
                                    <div className='space-y-4 lg:col-span-2'>
                                        <h3 className='text-lg md:text-xl font-semibold text-[#8B4513]'>Specifications</h3>
                                        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                                            {product.specifications.map((spec, idx) => (
                                                <div key={idx} className='flex items-center space-x-2'>
                                                    <span className='w-2 h-2 bg-[#DEB887] rounded-full'></span>
                                                    <span className='text-gray-700 text-sm md:text-base'>{spec}</span>
                                                </div>
                                            ))} 
                                        </div>
                                    </div>
                                </div>  
                            </div>
                        </div>
                    ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Bathlinen