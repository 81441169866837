import image from '../images/products/animal-8596500_640.png';


const Last = () => {
    return (
        <div className="bg-white  ">
            <div className=" mx-auto flex flex-col md:flex-row items-center justify-between  ">
                <div className="text-center md:text-left md:w-1/2 md:pr-8 py-10 md:py-20 px-4 md:px-40">
                    <div className="text-2xl md:text-4xl font-bold text-amber-800 mb-8">
                        <em>Experience pure comfort!</em><br />
                        <em>Enjoy life better than ever</em><br />
                        <em>with our guarantee!</em>
                    </div>
                    <div className="text-lg md:text-xl text-amber-700 ">
                        <em>100-night risk-free trial with full refund promise</em>
                    </div>
                    <button className="mt-2 bg-amber-600 hover:bg-amber-700 text-white font-bold py-3 px-6 rounded-lg transition duration-300">
                        Come Contact Us Now
                    </button>
                </div>

                <div className="md:w-1/2 mt-8 md:mt-0">
                    <img src={image} alt="Smiling man on couch with laptop" className="max-w-full w-full h-full object-cover transition duration-300" />
                </div>
            </div>
        </div>
    );
};

export default Last;