import React from 'react'
import background from "../images/backgroundoriginal.png";

const Bathlinen = () => {
    const products = [
        {
            title: "SplendourLux Luxury Duvet Collection",
            description: "A duvet of SplendourLUX is a type of bedding quilt, usually with a washable cover, that may be used in place of a bedspread and top sheet. While selecting a duvet cover, one must keep in mind that higher quality the down is the warmer and lighter the duvet will be. It is also important to match the required duvet warmth with one's needs.",
            additionalInfo: "A duvet cover of SplendourLUX is a coverlet made of two layers of fabric with a layer of cotton, wool, feathers or down in between, all stitched firmly together in a decorative crisscross design. One must take care of the duvet as washing it frequently can spoil its softness and fluffiness. Good quality duvet covers are made to protect the duvet and to add style to your bedroom.",
            features: [
                "Premium quality down filling",
                "Washable cover included",
                "Lightweight and warm",
                "Decorative crisscross design",
                "Multiple warmth levels",
                "Two-layer fabric construction",
                "Superior insulation",
                "Protective cover design",
                "Stylish appearance",
                "Easy maintenance",
                "Durable construction",
                "Various size options"
            ],
            specifications: [
                "Outer Cover: 100% premium cotton",
                "Inner Filling: High-quality down",
                "Construction: Two-layer fabric design",
                "Stitching: Decorative crisscross pattern",
                "Care: Spot clean or dry clean recommended",
                "Available in multiple warmth ratings"
            ],
            images: [
                "https://files.catbox.moe/e5flpr.png",
                "https://files.catbox.moe/8cy4d7.png",
                "https://files.catbox.moe/5e5xp4.png",
                "https://files.catbox.moe/t20bri.png",
                
            ]
        }
    ]

    return (
        <div style={{
            backgroundImage: `url(${background})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            position: 'relative',
            minHeight: '100vh',
        }}>
            <div style={{
                backdropFilter: 'blur(13px)',
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                padding: '2rem 0',
            }}>
                <div className='py-10 mx-auto px-4'>
                    <div className='px-4 md:px-8 lg:px-20'>
                    <h1 className='text-2xl md:text-3xl lg:text-4xl font-bold text-center mb-6 text-[#8B4513]'>Our Luxury Duvet Collection</h1>
                    {products.map((product, index) => (
                        <div key={index} className='space-y-8 rounded-lg'>
                            <div className='space-y-8'>
                                <div className='w-full'>
                                    <img
                                        src={product.images[0]}
                                        alt={`${product.title} view 1`}
                                        className='w-full h-[250px] md:h-[300px] lg:h-[400px] object-contain rounded-lg'
                                    />
                                </div>

                                <div className='grid grid-cols-1 lg:grid-cols-2 gap-8'>
                                    <div className='space-y-6'>
                                        <h2 className='text-2xl md:text-3xl font-bold text-[#8B4513]'>{product.title}</h2>
                                        <p className='text-gray-700 text-sm md:text-base'>{product.description}</p>
                                        <p className='text-gray-700 text-sm md:text-base'>{product.additionalInfo}</p>
                                    </div>
                                    <img
                                        src={product.images[2]}
                                        alt={`${product.title} view 3`}
                                        className='w-full h-[250px] md:h-[300px] lg:h-[400px] object-contain rounded-lg'
                                    />
                                </div>

                                <div className='grid grid-cols-1 lg:grid-cols-3 gap-8'>
                                    <div className='space-y-4 lg:col-span-2'>
                                        <h3 className='text-lg md:text-xl font-semibold text-[#8B4513]'>Key Features</h3>
                                        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                                            {product.features.map((feature, idx) => (
                                                <div key={idx} className='flex items-center space-x-2'>
                                                    <span className='w-2 h-2 bg-[#DEB887] rounded-full'></span>
                                                    <span className='text-gray-700 text-sm md:text-base'>{feature}</span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <img
                                        src={product.images[3]}
                                        alt={`${product.title} view 4`}
                                        className='w-full h-[250px] md:h-[300px] object-contain rounded-lg'
                                    />
                                </div>

                                <div className='grid grid-cols-1 lg:grid-cols-3 gap-8'>
                                    <img
                                        src={product.images[1]}
                                        alt={`${product.title} view 5`}
                                        className='w-full h-[250px] md:h-[300px] object-contain rounded-lg'
                                    />
                                    <div className='space-y-4 lg:col-span-2'>
                                        <h3 className='text-lg md:text-xl font-semibold text-[#8B4513]'>Specifications</h3>
                                        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                                            {product.specifications.map((spec, idx) => (
                                                <div key={idx} className='flex items-center space-x-2'>
                                                    <span className='w-2 h-2 bg-[#DEB887] rounded-full'></span>
                                                    <span className='text-gray-700 text-sm md:text-base'>{spec}</span>
                                                </div>
                                            ))} 
                                        </div>
                                    </div>
                                </div>  
                            </div>
                        </div>
                    ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Bathlinen