
import Hero from '../components/Hero'
import Why from '../components/Why'
import Products from '../components/Products'
import Last from '../components/Last'
import Cat from '../components/Cat'

const Home = () => {
    return (
        <div>
            <Hero/>
            <Why/>
            <Products/>
            <Cat/>
            <Last/>
        </div>
    )
}

export default Home