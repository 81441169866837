import logo from "../images/logo/Asset 2@6x.png";

const Footer = () => {
  return (
    <footer className="bg-[#a87c51] text-white py-8">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div>
            <img src={logo} alt="Logo" className="mb-4 w-56" />
            <h3 className="text-xl font-bold mb-4">About Us</h3>
            <p className="text-white">Experience luxury comfort with our premium bedding and home furnishing collections.</p>
          </div>
          <div className="flex flex-col">
            <h3 className="text-xl font-bold mb-4">Our Products</h3>
            <ul className="space-y-2">
              <li><a href="/mattresses" className="hover:text-gray-200 transition-colors">Premium Mattresses</a></li>
              <li><a href="/bedding" className="hover:text-gray-200 transition-colors">Luxury Bedding</a></li>
              <li><a href="/divans" className="hover:text-gray-200 transition-colors">Divan Collections</a></li>
              <li><a href="/accessories" className="hover:text-gray-200 transition-colors">Home Accessories</a></li>
            </ul>
          </div>
          <div className="flex flex-col">
            <h3 className="text-xl font-bold mb-4">Contact Info</h3>
            <ul className="space-y-2">
              <li>Head Office:</li>
              <li>No.131/1B, Dewala Road,Pamunuwa.Maharagama.</li>
              <li>Factory:</li>
              <li>No.277 C1, Pussella Farm Road, Ihalakosgama,Kosgama.</li>
              <li>Phone: <a href="tel:0712230000" className="hover:text-gray-200 transition-colors">(071) 223-0000</a>, <a href="tel:070312851" className="hover:text-gray-200 transition-colors">(070) 312-851</a>, <a href="tel:0703128152" className="hover:text-gray-200 transition-colors">(070) 312-8152</a>, <a href="tel:0703128153" className="hover:text-gray-200 transition-colors">(070) 312-8153</a></li>
              <li>Email: <a href="mailto:splendourlux@gmail.com" className="hover:text-gray-200 transition-colors">splendourlux@gmail.com</a></li>            </ul>
          </div>
          <div className="flex flex-col">
            <h3 className="text-xl font-bold mb-4">Follow Us</h3>
            <div className="flex space-x-4">
              <a href="https://facebook.com/luxurysleep" className="hover:text-gray-200 transition-colors" target="_blank" rel="noopener noreferrer">
                <span className="sr-only">Facebook</span>
                <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24"><path d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" /></svg>
              </a>
              <a href="https://twitter.com/luxurysleep" className="hover:text-gray-200 transition-colors" target="_blank" rel="noopener noreferrer">
                <span className="sr-only">Twitter</span>
                <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24"><path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z" /></svg>
              </a>
              <a href="https://linkedin.com/company/luxurysleep" className="hover:text-gray-200 transition-colors" target="_blank" rel="noopener noreferrer">
                <span className="sr-only">LinkedIn</span>
                <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24"><path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z" /></svg>
              </a>
            </div>
          </div>
        </div>
        <div className="border-t border-white mt-8 pt-8 text-center">
          <p>© {new Date().getFullYear()} Luxury Sleep Solutions. All rights reserved.</p>
        </div>
      </div>
    </footer>
  )
}

export default Footer