import React from 'react'
import background from "../images/backgroundoriginal.png";

const Pillow = () => {
    const products = [
        {
            title: "SplendourLux Premium Microfiber Gel Pillow",
            description: "The SplendourLux Premium Microfiber Gel pillow has been especially designed and manufactured with the best materials to ensure absolute comfort and maximum suppleness, giving you the most luxurious sleep you have ever experienced. The substance used both inside and out, gives you the perfect support for your head and neck while you sleep.",
            additionalInfo: "The SplendourLux Premium Microfiber Gel pillow is crafted with 100% cotton, high-thread-count fabrics, and is filled with a specially arranged profile of 'micro fine' branded fibres- making it the next best thing to a pillow filled with genuine down or feathers.",
            features: [
                "Medium firmness ideal for back and side sleepers",
                "Dense construction prevents packing down over time",
                "Elastic properties for easy movement during sleep",
                "Perfect blend of support and firmness",
                "Maintains shape without fluffing",
                "100% cotton high-thread-count fabric",
                "Micro fine branded fibres filling",
                "Superior head and neck support",
                "Maximum comfort and suppleness",
                "Durable and long-lasting",
                "Easy to maintain",
                "Hypoallergenic materials"
            ],
            specifications: [
                "Premium microfiber gel filling",
                "100% cotton cover",
                "High thread count fabric",
                "Medium firmness level",
                "Specially arranged profile of micro fine fibres",
                "Ergonomic design for neck support"
            ],
            images: [
                "https://files.catbox.moe/6inise.png",
                "https://files.catbox.moe/riz9j1.png",
                "https://files.catbox.moe/3vgmsi.png",
                "https://files.catbox.moe/ep2hps.png",
                "https://files.catbox.moe/ep2hps.png",
                "https://files.catbox.moe/riz9j1.png",
            ]
        }
    ]

    return (
        <div style={{
            backgroundImage: `url(${background})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'repeat',
            position: 'relative',
            minHeight: '100vh',
        }}>
            <div style={{
                backdropFilter: 'blur(13px)',
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                padding: '2rem 0',
            }}>
                <div className='py-10 mx-auto px-4'>
                    <div className='px-4 md:px-8 lg:px-20'>
                    <h1 className='text-2xl md:text-3xl lg:text-4xl font-bold text-center mb-6 text-[#8B4513]'>Our Premium Pillow Collection</h1>
                    {products.map((product, index) => (
                        <div key={index} className='space-y-8 rounded-lg'>
                            <div className='space-y-8'>
                                <div className='w-full'>
                                    <img
                                        src={product.images[0]}
                                        alt={`${product.title} view 1`}
                                        className='w-full h-[250px] md:h-[300px] lg:h-[400px] object-contain rounded-lg'
                                    />
                                </div>

                                <div className='grid grid-cols-1 lg:grid-cols-2 gap-8'>
                                    <div className='space-y-6'>
                                        <h2 className='text-2xl md:text-3xl font-bold text-[#8B4513]'>{product.title}</h2>
                                        <p className='text-gray-700 text-sm md:text-base'>{product.description}</p>
                                        <p className='text-gray-700 text-sm md:text-base'>{product.additionalInfo}</p>
                                    </div>
                                    <img
                                        src={product.images[2]}
                                        alt={`${product.title} view 3`}
                                        className='w-full h-[250px] md:h-[300px] lg:h-[400px] object-contain rounded-lg'
                                    />
                                </div>

                                <div className='grid grid-cols-1 lg:grid-cols-3 gap-8'>
                                    <div className='space-y-4 lg:col-span-2'>
                                        <h3 className='text-lg md:text-xl font-semibold text-[#8B4513]'>Key Features</h3>
                                        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                                            {product.features.map((feature, idx) => (
                                                <div key={idx} className='flex items-center space-x-2'>
                                                    <span className='w-2 h-2 bg-[#DEB887] rounded-full'></span>
                                                    <span className='text-gray-700 text-sm md:text-base'>{feature}</span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <img
                                        src={product.images[3]}
                                        alt={`${product.title} view 4`}
                                        className='w-full h-[250px] md:h-[300px] object-contain rounded-lg'
                                    />
                                </div>

                                <div className='grid grid-cols-1 lg:grid-cols-3 gap-8'>
                                    <img
                                        src={product.images[1]}
                                        alt={`${product.title} view 5`}
                                        className='w-full h-[250px] md:h-[300px] object-contain rounded-lg'
                                    />
                                    <div className='space-y-4 lg:col-span-2'>
                                        <h3 className='text-lg md:text-xl font-semibold text-[#8B4513]'>Specifications</h3>
                                        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                                            {product.specifications.map((spec, idx) => (
                                                <div key={idx} className='flex items-center space-x-2'>
                                                    <span className='w-2 h-2 bg-[#DEB887] rounded-full'></span>
                                                    <span className='text-gray-700 text-sm md:text-base'>{spec}</span>
                                                </div>
                                            ))} 
                                        </div>
                                    </div>
                                </div>  
                            </div>
                        </div>
                    ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Pillow