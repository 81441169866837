import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useRef, useState, useEffect } from 'react';
import backgroundImage from '../images/Hero/interior-design-neoclassical-style-with-furnishings-decor_23-2151199347.avif';
import bed from '../images/Hero/father-mother-daughter-lie-bed.jpg';
import { FaStar, FaUsers, FaTruck, FaAward, FaBars, FaTimes } from 'react-icons/fa';
import logo from "../images/logo/Logo@6x.png";
import logo1 from "../images/logo/Asset 2@6x.png";

const Hero = () => {
    const [isOpen, setIsOpen] = useState(false);
    const constraintsRef = useRef(null);
    const drawerRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (drawerRef.current && !(drawerRef.current as Node).contains(event.target as Node) && isOpen) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: { 
            opacity: 1,
            transition: { 
                duration: 0.5,
                staggerChildren: 0.2
            }
        }
    };

    const itemVariants = {
        hidden: { y: 20, opacity: 0 },
        visible: { 
            y: 0, 
            opacity: 1,
            transition: { type: "spring", stiffness: 100 }
        }
    };

    const statsVariants = {
        hidden: { scale: 0.8, opacity: 0 },
        visible: { 
            scale: 1, 
            opacity: 1,
            transition: { type: "spring", damping: 12 }
        },
        hover: { 
            scale: 1.1,
            rotate: [0, 2, -2, 0],
            transition: { duration: 0.3 }
        }
    };

    const drawerVariants = {
        open: { x: 0 },
        closed: { x: "100%" }
    };

    return (
        <motion.div 
            className="relative min-h-screen font-sans overflow-x-hidden"
            initial="hidden"
            animate="visible"
            variants={containerVariants}
            ref={constraintsRef}
        >
            <motion.div
                className="absolute inset-0 bg-cover bg-center z-0"
                style={{ backgroundImage: `url(${backgroundImage})` }}
                initial={{ scale: 1.2, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{ duration: 1.5 }}
            >
                <div className="absolute inset-0 bg-gradient-to-b from-[#8B4513]/70 to-transparent backdrop-blur-[1px]"></div>
            </motion.div>

            <motion.nav 
                className="relative z-20 flex justify-between items-center p-4 sm:p-8"
                initial={{ y: -100 }}
                animate={{ y: 0 }}
                transition={{ type: "spring", stiffness: 100 }}
            >
                <Link to="/">
                <motion.div 
                    className="flex items-center space-x-2"
                    whileHover={{ scale: 1.05 }}
                >
                    <img src={logo} alt="Logo" className="h-8 sm:h-10" />
                </motion.div>
                </Link>
                
                
                <div className="hidden md:flex space-x-16">
                    {['Collections', 'About Us', 'Contact Us'].map((item, index) => (
                        <motion.span
                            key={item}
                            initial={{ opacity: 0, y: -20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: index * 0.1 }}
                        >
                            <Link to={`/${item.toLowerCase().replace(' ', '')}`} className="text-white hover:text-[#F5DEB3] transition-all duration-300 text-lg font-medium sour-gummy-nav">{item}</Link>
                        </motion.span>
                    ))}
                </div>
                
                <motion.div 
                    className="hidden md:block text-white text-lg font-medium"
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                >
                    <a href="tel:+94712230000" className=" ">📞 +94 71 22 3 0000</a>                </motion.div>

                <motion.button
                    className="md:hidden text-white text-xl sm:text-2xl"
                    onClick={() => setIsOpen(!isOpen)}
                    whileTap={{ scale: 0.9 }}
                >
                    <FaBars />
                </motion.button>
            </motion.nav>

            <motion.div
                ref={drawerRef}
                className="fixed top-0 right-0 h-full w-64 bg-white z-30 border-l border-white/20"
                initial="closed"
                animate={isOpen ? "open" : "closed"}
                variants={drawerVariants}
                drag="x"
                dragConstraints={{ left: 0, right: 100 }}
                dragElastic={0.2}
                onDragEnd={(event, info) => {
                    if (info.offset.x > 100) {
                        setIsOpen(false);
                    }
                }}
            >
                <div className="w-full h-full backdrop-blur-xl p-8">
                    <img src={logo1} alt="" className='mt-10'/>
                    <motion.button
                        className="absolute top-4 right-4 text-[#8B4513] text-xl hover:text-[#DEB887] transition-colors"
                        onClick={() => setIsOpen(false)}
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                    >
                        <FaTimes />
                    </motion.button>
                    <div className="flex flex-col space-y-8 mt-10">
                        {['Collections', 'About Us', 'Contact Us'].map((item, index) => (
                            <motion.div
                                key={item}
                                whileHover={{ x: 10 }}
                                whileTap={{ scale: 0.95 }}
                            >
                                <Link 
                                    to={`/${item.toLowerCase().replace(' ', '')}`} 
                                    className="text-[#8B4513] hover:text-[#DEB887] transition-all duration-300 text-lg font-medium"
                                    onClick={() => setIsOpen(false)}
                                >
                                    {item}
                                </Link>
                            </motion.div>
                        ))}
                        <div className="pt-8 border-t border-[#8B4513]">
                        <a href="tel:+94712230000" className=" ">📞 +94 71 22 3 0000</a> 
                        </div>
                    </div>
                </div>
            </motion.div>

            <div className="relative z-10 flex flex-col-reverse md:flex-row items-center justify-center gap-6 md:gap-20 min-h-[calc(100vh-120px)] text-white px-4 md:px-16 py-8 md:py-0">
                <motion.div 
                    className="text-center md:text-left max-w-2xl"
                    variants={itemVariants}
                >
                    <motion.h1 
                        className="text-3xl sm:text-4xl md:text-7xl font-bold mb-4 sm:mb-8 tracking-tight leading-tight sour-gummy-heading"
                        initial={{ x: -100, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ type: "spring", damping: 12 }}
                    >
                        Welcome to <motion.span 
                            className="bg-gradient-to-r from-[#DEB887] to-[#F5DEB3] text-transparent bg-clip-text font-extrabold"
                            whileHover={{ scale: 1.1 }}
                            transition={{ type: "spring", stiffness: 300 }}
                        ><strong>SplendourLux</strong></motion.span>
                    </motion.h1>
                    <motion.p 
                        className="text-lg sm:text-xl md:text-2xl leading-relaxed text-white font-light font-['Libre_Baskerville'] px-2 sm:px-0"
                        variants={itemVariants}
                    >
                        Elevate your sleep sanctuary with our premium collection of mattresses, pillows, bed linens, and home furnishings. From luxurious divan beds to elegant curtains, discover comfort in every detail.
                    </motion.p>
                    <div className="flex flex-wrap justify-center md:justify-start gap-3 sm:gap-4 md:gap-8 mt-6 sm:mt-8">
                        {[
                            { icon: <div className="flex text-[#FFD700]"><FaStar /><FaStar /><FaStar /><FaStar /><FaStar /></div>, text: "4.9/5 Rating" },
                            { icon: <FaUsers className="text-xl sm:text-2xl text-[#FFD700]" />, text: "10K+ Customers" },
                            { icon: <FaTruck className="text-xl sm:text-2xl text-[#FFD700]" />, text: "Free Shipping" },
                            { icon: <FaAward className="text-xl sm:text-2xl text-[#FFD700]" />, text: "Premium Quality" }
                        ].map((item, index) => (
                            <motion.div
                                key={index}
                                className="flex flex-col items-center bg-[#8B4513]/10 backdrop-blur-md p-3 sm:p-4 rounded-xl border border-[#DEB887]/20"
                                variants={statsVariants}
                                whileHover="hover"
                                custom={index}
                            >
                                {item.icon}
                                <span className="mt-1 sm:mt-2 text-xs sm:text-sm md:text-base">{item.text}</span>
                            </motion.div>
                        ))}
                    </div>
                    <Link to="/collections">
                        <motion.button 
                            className="mt-6 sm:mt-8 md:mt-12 px-6 sm:px-8 md:px-10 py-2 sm:py-3 md:py-4 bg-gradient-to-r from-[#8B4513] to-[#DEB887] text-white rounded-lg font-medium text-base sm:text-lg shadow-lg"
                            whileHover={{ scale: 1.05 }} 
                            whileTap={{ scale: 0.95 }}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ delay: 0.5 }}
                        >
                            View Collections
                        </motion.button>
                    </Link>
                </motion.div>
                <motion.div 
                    className="w-[250px] h-[250px] sm:w-[300px] sm:h-[300px] md:w-[500px] md:h-[500px] rounded-full mb-6 sm:mb-10 md:mb-4 overflow-hidden shadow-2xl border-4 sm:border-8 border-white"
                    initial={{ rotate: 180, scale: 0 }}
                    animate={{ rotate: 0, scale: 1 }}
                    transition={{ type: "spring", damping: 15, duration: 1 }}
                    whileHover={{ 
                        scale: 1.05,
                        borderColor: "rgba(222, 184, 135, 0.5)",
                        boxShadow: "0 0 30px rgba(222, 184, 135, 0.3)"
                    }}
                >
                    <img 
                        src={bed} 
                        alt="Luxury bedroom collection" 
                        className="w-full h-full object-cover filter brightness-105 contrast-105"
                    />
                </motion.div>
            </div>
        </motion.div>
    );
};
export default Hero;