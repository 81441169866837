import React, { useRef, useState, useEffect } from 'react'
import {  FaBars, FaTimes } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import logo from "../images/logo/Logo@6x.png"
import logo1 from "../images/logo/Asset 2@6x.png"

const Contact = () => {
    const [isOpen, setIsOpen] = useState(false) 
    const drawerRef = useRef(null)

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (drawerRef.current && !(drawerRef.current as Node).contains(event.target as Node) && isOpen) {
                setIsOpen(false)
            }
        }

        if (isOpen) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'unset'
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
            document.body.style.overflow = 'unset'
        }
    }, [isOpen])

    const drawerVariants = {
        open: { x: 0 },
        closed: { x: "100%" }
    }

    return (
        <div className='min-h-screen md:px-10'>
            {isOpen && (
                <div className="fixed inset-0 bg-black/50 backdrop-blur-sm z-20" onClick={() => setIsOpen(false)} />
            )}
            <motion.nav
                className="relative flex justify-between items-center p-4 sm:p-8"
                initial={{ y: -100 }}
                animate={{ y: 0 }}
                transition={{ type: "spring", stiffness: 100 }}
            >
                <Link to="/">
                    <motion.div
                        className="flex items-center space-x-2"
                        whileHover={{ scale: 1.05 }}
                    >
                        <img src={logo} alt="Logo" className="h-8 sm:h-10" />
                    </motion.div>
                </Link>

                <div className="hidden md:flex space-x-16 ">
                    {['Collections', 'About Us', 'Contact Us'].map((item, index) => (
                        <motion.span
                            key={item}
                            initial={{ opacity: 0, y: -20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: index * 0.1 }}
                        >
                            <Link to={`/${item.toLowerCase().replace(' ', '')}`} className="text-gray-600 hover:text-[#F5DEB3] transition-all duration-300 text-lg font-medium sour-gummy-nav">{item}</Link>
                        </motion.span>
                    ))}
                </div>

                <motion.div
                    className="hidden md:block text-gray-600 text-lg font-medium"
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                >
                    <a href="tel:+94712230000" className=" ">📞 +94 71 22 3 0000</a>
                </motion.div>

                <motion.button
                    className="md:hidden text-black text-xl sm:text-2xl"
                    onClick={() => setIsOpen(!isOpen)}
                    whileTap={{ scale: 0.9 }}
                >
                    <FaBars />
                </motion.button>
            </motion.nav>

            <motion.div
                ref={drawerRef}
                className="fixed top-0 right-0 h-full w-64 bg-white z-30 border-l border-white/20"
                initial="closed"
                animate={isOpen ? "open" : "closed"}
                variants={drawerVariants}
                drag="x"
                dragConstraints={{ left: 0, right: 100 }}
                dragElastic={0.2}
                onDragEnd={(event, info) => {
                    if (info.offset.x > 100) {
                        setIsOpen(false)
                    }
                }}
            >
                <div className="w-full h-full backdrop-blur-xl p-8">
                    <img src={logo1} alt="" className='mt-10' />
                    <motion.button
                        className="absolute top-4 right-4 text-[#8B4513] text-xl hover:text-[#DEB887] transition-colors"
                        onClick={() => setIsOpen(false)}
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                    >
                        <FaTimes />
                    </motion.button>
                    <div className="flex flex-col space-y-8 mt-10">
                        {['Collections', 'About Us', 'Contact Us'].map((item, index) => (
                            <motion.div
                                key={item}
                                whileHover={{ x: 10 }}
                                whileTap={{ scale: 0.95 }}
                            >
                                <Link
                                    to={`/${item.toLowerCase().replace(' ', '')}`}
                                    className="text-[#8B4513] hover:text-[#DEB887] transition-all duration-300 text-lg font-medium"
                                    onClick={() => setIsOpen(false)}
                                >
                                    {item}
                                </Link>
                            </motion.div>
                        ))}
                        <div className="pt-8 border-t border-[#8B4513]">
                            <a href="tel:+94712230000" className=" ">📞 +94 71 22 3 0000</a>
                        </div>
                    </div>
                </div>
            </motion.div>

            <div className="container mx-auto px-4 py-12">
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                    className="text-center mb-12"
                >
                    <h1 className="text-4xl font-bold text-[#8B4513] mb-4">Contact Us</h1>
                    <p className="text-gray-600">We'd love to hear from you. Please fill out this form or use our contact information below.</p>
                </motion.div>

                <div className="grid md:grid-cols-2 gap-12">
                    <motion.div
                        initial={{ opacity: 0, x: -50 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.5, delay: 0.2 }}
                        className="bg-white p-8 rounded-lg shadow-lg transition-shadow duration-300"
                    >
                        <form className="space-y-6">
                            <div>
                                <label className="block text-gray-700 mb-2 font-medium">Name</label>
                                <input type="text" className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-[#8B4513] focus:ring-2 focus:ring-[#8B4513]/20 transition-all duration-300" />
                            </div>
                            <div>
                                <label className="block text-gray-700 mb-2 font-medium">Email</label>
                                <input type="email" className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-[#8B4513] focus:ring-2 focus:ring-[#8B4513]/20 transition-all duration-300" />
                            </div>
                            <div>
                                <label className="block text-gray-700 mb-2 font-medium">Message</label>
                                <textarea rows={4} className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-[#8B4513] focus:ring-2 focus:ring-[#8B4513]/20 transition-all duration-300"></textarea>
                            </div>
                            <motion.button
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                                className="w-full bg-[#8B4513] text-white py-3 rounded-lg hover:bg-[#DEB887] transition-all duration-300 shadow-md hover:shadow-lg"
                            >
                                Send Message
                            </motion.button>
                        </form>
                    </motion.div>

                    <motion.div
                        initial={{ opacity: 0, x: 50 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.5, delay: 0.4 }}
                        className="space-y-8"
                    >
                        <div className="bg-white p-8 rounded-lg shadow-lg transition-shadow duration-300">
                            <div className="flex flex-col space-y-6">
                                <h3 className="text-2xl font-bold text-[#8B4513] border-b pb-4">Contact Info</h3>
                                <div className="space-y-4">
                                    <div className="p-4 bg-[#F5DEB3]/10 rounded-lg">
                                        <h4 className="font-semibold text-[#8B4513] mb-2">Head Office</h4>
                                        <p className="text-gray-600">No.131/1B, Dewala Road, Pamunuwa, Maharagama.</p>
                                    </div>
                                    
                                    <div className="p-4 bg-[#F5DEB3]/10 rounded-lg">
                                        <h4 className="font-semibold text-[#8B4513] mb-2">Factory</h4>
                                        <p className="text-gray-600">No.277 C1, Pussella Farm Road, Ihalakosgama, Kosgama.</p>
                                    </div>
                                    
                                    <div className="p-4 bg-[#F5DEB3]/10 rounded-lg">
                                        <h4 className="font-semibold text-[#8B4513] mb-2">Phone Numbers</h4>
                                        <div className="space-y-2 text-gray-600">
                                            <a href="tel:0712230000" className="block hover:text-[#8B4513] transition-colors">📞 (071) 223-0000</a>
                                            <a href="tel:070312851" className="block hover:text-[#8B4513] transition-colors">📞 (070) 312-851</a>
                                            <a href="tel:0703128152" className="block hover:text-[#8B4513] transition-colors">📞 (070) 312-8152</a>
                                            <a href="tel:0703128153" className="block hover:text-[#8B4513] transition-colors">📞 (070) 312-8153</a>
                                        </div>
                                    </div>
                                    
                                    <div className="p-4 bg-[#F5DEB3]/10 rounded-lg">
                                        <h4 className="font-semibold text-[#8B4513] mb-2">Email</h4>
                                        <a href="mailto:splendourlux@gmail.com" className="text-gray-600 hover:text-[#8B4513] transition-colors">📧 splendourlux@gmail.com</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                </div>
            </div>
        </div>
    )
}

export default Contact