import mattress from '../images/LandingProducts/mattress.png';
import background from '../images/LandingProducts/sssplatter.png';
import pillows from '../images/LandingProducts/pillows.png';
import sheets from '../images/LandingProducts/bedsheets.png';

import { FaBed, FaCloud, FaStar, FaCheckCircle, FaArrowRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const products = [
    {
        title: "Premium Mattress Collection",
        description: "Our flagship mattress line featuring advanced memory foam technology and innovative sleep solutions. Each mattress is crafted with premium materials for optimal comfort and support.",
        image: { mattress },
        features: ["15 Year Warranty", "Temperature Regulating", "Orthopedic Certified", "Zero Motion Transfer"],
        icon: <FaBed />
    },
    {
        title: "Ergonomic Sleep System",
        description: "A revolutionary sleep system combining our premium pillows with posture-perfect technology. Designed in collaboration with sleep experts for therapeutic benefits.",
        image: { pillows },
        features: ["Medical Grade Foam", "Cooling Technology", "Spine Alignment", "Anti-Microbial"],
        icon: <FaCloud />
    },
    {
        title: "Luxury Bedroom Suite",
        description: "Experience hotel-grade luxury with our complete bedroom collection. Featuring Egyptian cotton linens, premium duvets, and designer accessories.",
        image: { sheets },
        features: ["1000 Thread Count", "Hotel Collection", "Signature Design", "Premium Materials"],
        icon: <FaStar />
    },







];

const Products = () => {
    return (
        <div className="min-h-screen bg-transparent px-4 py-8 md:py-8 sm:p-6 relative overflow-hidden">
            <h1 className="text-3xl sm:text-4xl md:text-6xl font-bold text-[#8B4513] text-center mb-4 relative z-10">
                        Our Collections
                    </h1>
            <div className="absolute inset-0" style={{ backgroundImage: `url(${background})`, backgroundSize: '200%', backgroundRepeat: 'repeat', opacity: 0.25, transform: 'scale(1.5)' }}></div>
            {products.map((product, index) => (
                <div key={index}

                    className={`flex flex-col-reverse md:flex-row items-center backdrop-blur-sm p-4 sm:p-6 md:p-8 rounded-2xl mb-8 md:mb-12 mx-auto max-w-7xl relative ${index % 2 === 0 ? 'md:flex-row' : 'md:flex-row-reverse'}`}>
                    
                    <div className="w-full md:w-1/2 p-2 sm:p-4 md:p-8">
                        <div className="flex items-center gap-2 sm:gap-3 mb-3 sm:mb-4 md:mb-6">
                            <h1 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold text-black">{product.title}</h1>
                        </div>
                        <p className="text-sm sm:text-base md:text-lg lg:text-xl xl:text-2xl text-[#5c4033] mb-4 sm:mb-6 md:mb-8 leading-relaxed">{product.description}</p>
                        <div className="grid grid-cols-2 sm:flex sm:flex-wrap gap-2 sm:gap-3 mb-4 sm:mb-6 md:mb-8">
                            {product.features.map((feature, idx) => (
                                <div key={idx} className="flex items-center bg-white rounded-full px-2 sm:px-4 md:  py-1.5 sm:py-2 md:py-3">
                                    <FaCheckCircle className="mr-1 sm:mr-2 text-[#8B4513] text-xs sm:text-sm md:text-base lg:text-lg" />
                                    <span className="text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl font-medium text-[#5c4033]">{feature}</span>
                                </div>
                            ))}
                        </div>
                        <Link to="/collections#top" className="w-full sm:w-auto bg-[#8B4513] text-white font-semibold py-2 md:py-3 px-4 sm:px-6 md:px-8 rounded-full shadow-md text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl hover:bg-[#6d3610] transition-colors">
                            View Collection
                            <FaArrowRight className="ml-2 inline" />
                        </Link>                    </div>

                    <div className="w-full md:w-1/2 flex justify-center p-2 sm:p-4 md:p-6 mb-4 md:mb-0">
                        <img
                            src={typeof product.image === 'string' ? product.image : Object.values(product.image)[0]}
                            alt={product.title}
                            className="w-full h-full object-cover rounded-2xl"
                        />
                    </div>
                </div>
            ))}
        </div>
    );
};


export default Products;