
import { Box, Container, Grid, Typography, Card, CardContent } from '@mui/material';
import { styled } from '@mui/material/styles';
import image1 from '../images/products/download.png';
import PremiumIcon from '@mui/icons-material/Stars';
import CollectionIcon from '@mui/icons-material/Collections';
import CraftsmanshipIcon from '@mui/icons-material/Build';
import ComfortIcon from '@mui/icons-material/LocalHotel';
import PricingIcon from '@mui/icons-material/AttachMoney';
import SatisfactionIcon from '@mui/icons-material/ThumbUp';
import background from '../images/products/layered-waves-haikei.png';

const StyledCard = styled(Card)(({ theme }) => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    borderRadius: theme.spacing(2),
    background: 'white',
    // boxShadow: '0 8px 32px 0 rgba(168, 124, 81, 0.37)',
    transition: 'all 0.3s ease-in-out',
    position: 'relative',
    overflow: 'hidden',
    '&:before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'linear-gradient(145deg, rgba(168, 124, 81, 0.05) 0%, rgba(168, 124, 81, 0.15) 100%)',
        opacity: 0,
        transition: 'opacity 0.3s ease-in-out',
    },
    '&:hover': {
        transform: 'translateY(-5px) scale(1.02)',
        boxShadow: '0 8px 32px 0 rgba(168, 124, 81, 0.47)',
        '&:before': {
            opacity: 1,
        }
    },
}));
const Why = () => {
    const reasons = [
        {
            title: 'Premium Quality Materials',
            description: 'All our products are crafted using the finest materials, ensuring durability and comfort that lasts for years.',
            icon: <PremiumIcon sx={{ fontSize: 40, color: '#a87c51' }} />
        },
        {
            title: 'Extensive Collection',
            description: 'From luxury mattresses to elegant curtains, we offer a comprehensive range of bedroom and bath essentials.',
            icon: <CollectionIcon sx={{ fontSize: 40, color: '#a87c51' }} />
        },
        {
            title: 'Expert Craftsmanship',
            description: 'Each product is manufactured with meticulous attention to detail and superior craftsmanship.',
            icon: <CraftsmanshipIcon sx={{ fontSize: 40, color: '#a87c51' }} />
        },
        {
            title: 'Comfort Guarantee',
            description: 'Our products are designed to provide ultimate comfort and relaxation for a better sleep experience.',
            icon: <ComfortIcon sx={{ fontSize: 40, color: '#a87c51' }} />
        },
        {
            title: 'Competitive Pricing',
            description: 'Premium quality products at reasonable prices, offering the best value for your investment.',
            icon: <PricingIcon sx={{ fontSize: 40, color: '#a87c51' }} />
        },
        {
            title: 'Customer Satisfaction',
            description: 'Dedicated customer service and after-sales support to ensure complete satisfaction.',
            icon: <SatisfactionIcon sx={{ fontSize: 40, color: '#a87c51' }} />
        },
    ];

    return (
        <Box 
            py={{ xs: 6, md: 12 }} 
            sx={{
                backgroundImage: `url(${background})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backdropFilter: 'blur(5px)',
                WebkitBackdropFilter: 'blur(5px)'
            }}
        >
            <Container maxWidth="lg">
                <Grid container spacing={{ xs: 4, md: 6 }} alignItems="center">
                    <Grid item xs={12} md={7}>
                        <Typography
                            variant="h2"
                            gutterBottom
                            sx={{
                                fontWeight: 700,
                                fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' },
                                background: 'linear-gradient(45deg, #a87c51 30%, #a87c51 90%)',
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent'
                            }}
                        >
                            Why Choose Us?
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            color="text.secondary"
                            mb={{ xs: 4, md: 8 }}
                            sx={{ maxWidth: '800px', fontSize: { xs: '1rem', md: '1.1rem' } }}
                        >
                            Discover the advantages of choosing our premium home furnishing solutions
                        </Typography>
                        <Grid container spacing={{ xs: 2, sm: 4 }}>
                            {reasons.map((reason, index) => (
                                <Grid item xs={12} sm={6} key={index}>
                                    <StyledCard>
                                        <CardContent sx={{ height: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                {reason.icon}
                                                <Typography
                                                    variant="h5"
                                                    component="h3"
                                                    sx={{ 
                                                        fontWeight: 600,
                                                        fontSize: { xs: '1.2rem', sm: '1.4rem', md: '1.5rem' }
                                                    }}
                                                >
                                                    {reason.title}
                                                </Typography>
                                            </Box>
                                            <Typography
                                                variant="body1"
                                                className="text-gray-700"
                                                sx={{ 
                                                    lineHeight: 1.7,
                                                    fontSize: { xs: '0.9rem', sm: '1rem' }
                                                }}
                                            >
                                                {reason.description}
                                            </Typography>
                                        </CardContent>
                                    </StyledCard>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={5} sx={{ display: { xs: 'none', md: 'block' } }}>
                        <Box 
                            component="img" 
                            src={image1} 
                            alt="Satisfied customer" 
                            sx={{ 
                                width: '100%', 
                                height: '100%',
                                objectFit: 'cover',
                                borderRadius: 2,
                                
                            }} 
                        />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default Why; 