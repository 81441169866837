import background from '../images/girl-1851360_1280.jpg'
import background2 from "../images/girl-1851360_1280-croped.jpg";

const Cat = () => {
    return (
        <div className="relative h-[600px] sm:h-[700px] lg:h-screen">
            <div
                className="absolute inset-0 bg-cover object-cover bg-center bg-no-repeat animate-fade-in"
                style={{ backgroundImage: `url(${window.innerWidth >= 1024 ? background : background2})` }}
            >
                <div className="h-full w-full sm:w-3/4 md:w-1/2 lg:w-1/3 p-6 md:p-8 lg:p-12 backdrop-blur-sm bg-black/30 md:bg-white/50 hover:bg-white/60 transition-all duration-300 flex flex-col justify-center mx-auto sm:mr-0 shadow-2xl ">
                    <h2 className="text-4xl md:text-4xl lg:text-5xl font-bold mb-6 md:mb-6 lg:mb-8 text-white bg-gradient-to-r from-gray-800 to-black bg-clip-text text-transparent animate-slide-in-right text-center sm:text-left">Perfect For Your Comfort</h2>
                    <ul className="text-black text-xl md:text-xl lg:text-2xl space-y-4 md:space-y-4 lg:space-y-6 animate-slide-in-left">
                        <li className="flex items-center gap-3 md:gap-3 hover:translate-x-2 transition-transform duration-200 justify-center sm:justify-start">
                            <span className="text-white text-2xl md:text-2xl">✓</span>
                            <span className="font-semibold text-white cursor-pointer hover:text-gray-800">Premium Mattresses</span>
                        </li>
                        <li className="flex items-center gap-3 md:gap-3 hover:translate-x-2 transition-transform duration-200 justify-center sm:justify-start">
                            <span className="text-white text-2xl md:text-2xl">✓</span>
                            <span className="font-semibold text-white cursor-pointer hover:text-gray-800">Luxurious Pillows</span>
                        </li>
                        <li className="flex items-center gap-3 md:gap-3 hover:translate-x-2 transition-transform duration-200 justify-center sm:justify-start">
                            <span className="text-white text-2xl md:text-2xl">✓</span>
                            <span className="font-semibold text-white cursor-pointer hover:text-gray-800">Cozy Bean Bags</span>
                        </li>
                        <li className="flex items-center gap-3 md:gap-3 hover:translate-x-2 transition-transform duration-200 justify-center sm:justify-start">
                            <span className="text-white text-2xl md:text-2xl">✓</span>
                            <span className="font-semibold text-white cursor-pointer hover:text-gray-800">Comfortable Cushions</span>
                        </li>
                        <li className="flex items-center gap-3 md:gap-3 hover:translate-x-2 transition-transform duration-200 justify-center sm:justify-start">
                            <span className="text-white text-2xl md:text-2xl">✓</span>
                            <span className="font-semibold text-white cursor-pointer hover:text-gray-800">Soft Bedding Sets</span>
                        </li>
                        <li className="flex items-center gap-3 md:gap-3 hover:translate-x-2 transition-transform duration-200 justify-center sm:justify-start">
                            <span className="text-white text-2xl md:text-2xl">✓</span>
                            <span className="font-semibold text-white cursor-pointer hover:text-gray-800">Memory Foam Toppers</span>
                        </li>
                        <li className="flex items-center gap-3 md:gap-3 hover:translate-x-2 transition-transform duration-200 justify-center sm:justify-start">
                            <span className="text-white text-2xl md:text-2xl">✓</span>
                            <span className="font-semibold text-white cursor-pointer hover:text-gray-800">Weighted Blankets</span>
                        </li>
                        <li className="flex items-center gap-3 md:gap-3 hover:translate-x-2 transition-transform duration-200 justify-center sm:justify-start">
                            <span className="text-white text-2xl md:text-2xl">✓</span>
                            <span className="font-semibold text-white cursor-pointer hover:text-gray-800">Orthopedic Support</span>
                        </li>
                        {/* <li className="flex items-center gap-3 md:gap-3 hover:translate-x-2 transition-transform duration-200 justify-center sm:justify-start">
                            <span className="text-white text-2xl md:text-2xl">✓</span>
                            <span className="font-semibold text-white cursor-pointer hover:text-gray-800">Neck Support Pillows</span>
                        </li>
                        <li className="flex items-center gap-3 md:gap-3 hover:translate-x-2 transition-transform duration-200 justify-center sm:justify-start">
                            <span className="text-white text-2xl md:text-2xl">✓</span>
                            <span className="font-semibold text-white cursor-pointer hover:text-gray-800">Bamboo Bed Sheets</span>
                        </li> */}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Cat