import React from 'react'
import background from "../images/backgroundoriginal.png";

const Matttresses = () => {
    const products = [
        {
            title: "Natural Splendour Lux Foam",
            description: "For comfortable sleep, you need a responsive surface for pressure relief and appropriate support for your spine and your shape. Natural Splendour Lux foam is so versatile it can meet all of these needs. With a customizable, layered design, splendour lux mattress can be made very soft, very firm or a wide range of options in between--even on different sides.",
            additionalInfo: "Unlike most other foams, Splendour Lux breathes, which discourages buildup of moisture and heat in the mattress interior. Splendour Lux does not transfer motion the way most innersprings do, so you can say 'goodnight' to bouncing in your partner's wake. Splendour Lux mattress distributes your weight, how well it supports your spine and relieves pressure, and how durable the materials are.",
            features: [
                "100% Natural Latex",
                "Single Block Quilted Encasement (Cotton/Wool)",
                "Organic Dunlop Core Soft Soft Medium Firm Medium",
                "Single block construction",
                "Quick Response natural latex foam",
                "Covered by organic cotton or 300 gsm premium fabric",
                "Naturally hypoallergenic and anti-microbial",
                "Latex foam mattresses can last for 15 to 25 years",
                "100% Biodegradable raw material",
                "Tested for VOC (Volatile Organic Compound)",
                "Dust mite Free",
                "Conforms to body curves with maximum back support"
            ],
            specifications: [
                "90% Latex with 10% Rebonded Latex",
                "60% Rebonded Latex with 40% Rebonded PU",
                "Made of natural latex foam",
                "Triple layered for comfort top, Orthopedic base and support layer",
                "Most durable foam in the world (20+ years)",
                "High-density foam with quilted cover and tape edged finish"
            ],
            images: [
                "https://files.catbox.moe/1gz1vj.png",
                "https://files.catbox.moe/rszpz3.png",
                "https://files.catbox.moe/35j43s.png",
                "https://files.catbox.moe/px1d19.png",
                "https://files.catbox.moe/fcz3dh.png"
            ]
        }
    ]

    return (
        <div style={{
            backgroundImage: `url(${background})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            position: 'relative',
            minHeight: '100vh',
        }}>
            <div style={{
                backdropFilter: 'blur(13px)',
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                padding: '2rem 0',
            }}>
                <div className='py-10 mx-auto px-4'>
                    <div className='px-4 md:px-8 lg:px-20'>
                    <h1 className='text-2xl md:text-3xl lg:text-4xl font-bold text-center mb-6 text-[#8B4513]'>Our Premium Mattress Collection</h1>
                    {products.map((product, index) => (
                        <div key={index} className='space-y-8 rounded-lg'>
                            <div className='space-y-8'>
                                <div className='w-full'>
                                    <img
                                        src={product.images[0]}
                                        alt={`${product.title} view 1`}
                                        className='w-full h-[250px] md:h-[300px] lg:h-[400px] object-contain rounded-lg'
                                    />
                                </div>

                                <div className='grid grid-cols-1 lg:grid-cols-2 gap-8'>
                                    <div className='space-y-6'>
                                        <h2 className='text-2xl md:text-3xl font-bold text-[#8B4513]'>{product.title}</h2>
                                        <p className='text-gray-700 text-sm md:text-base'>{product.description}</p>
                                        <p className='text-gray-700 text-sm md:text-base'>{product.additionalInfo}</p>
                                    </div>
                                    <img
                                        src={product.images[2]}
                                        alt={`${product.title} view 3`}
                                        className='w-full h-[250px] md:h-[300px] lg:h-[400px] object-contain rounded-lg'
                                    />
                                </div>

                                <div className='grid grid-cols-1 lg:grid-cols-3 gap-8'>
                                    <div className='space-y-4 lg:col-span-2'>
                                        <h3 className='text-lg md:text-xl font-semibold text-[#8B4513]'>Key Features</h3>
                                        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                                            {product.features.map((feature, idx) => (
                                                <div key={idx} className='flex items-center space-x-2'>
                                                    <span className='w-2 h-2 bg-[#DEB887] rounded-full'></span>
                                                    <span className='text-gray-700 text-sm md:text-base'>{feature}</span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <img
                                        src={product.images[3]}
                                        alt={`${product.title} view 4`}
                                        className='w-full h-[250px] md:h-[300px] object-contain rounded-lg'
                                    />
                                </div>

                                <div className='grid grid-cols-1 lg:grid-cols-3 gap-8'>
                                    <img
                                        src={product.images[4]}
                                        alt={`${product.title} view 5`}
                                        className='w-full h-[250px] md:h-[300px] object-contain rounded-lg'
                                    />
                                    <div className='space-y-4 lg:col-span-2'>
                                        <h3 className='text-lg md:text-xl font-semibold text-[#8B4513]'>Specifications</h3>
                                        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                                            {product.specifications.map((spec, idx) => (
                                                <div key={idx} className='flex items-center space-x-2'>
                                                    <span className='w-2 h-2 bg-[#DEB887] rounded-full'></span>
                                                    <span className='text-gray-700 text-sm md:text-base'>{spec}</span>
                                                </div>
                                            ))} 
                                        </div>
                                    </div>
                                </div>  
                            </div>
                        </div>
                    ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Matttresses