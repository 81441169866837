import React from 'react'
import background from "../images/backgroundoriginal.png";

const Bathlinen = () => {
    const products = [
        {
            title: "SplendourLux Luxury Bath and Pool Collection",
            description: "Experience the ultimate in luxury and comfort with our premium bath and pool collection. Each piece is crafted with meticulous attention to detail, using the finest materials to ensure maximum absorbency and durability.",
            additionalInfo: "Our collection features soft ring spun 100% cotton yarns in both pile and ground construction, providing a smooth feel and superior performance.",
            features: [
                "Ultimate moisture absorbency",
                "Premium durability",
                "Soft ring spun cotton construction",
                "Smooth luxurious feel",
                "100% virgin cotton yarns",
                "Superior ground construction",
                "Quick-drying properties",
                "Perfect for daily use",
                "Long-lasting quality",
                "Easy care and maintenance",
                "Elegant design",
                "Multiple size options"
            ],
            specifications: [
                "Pool Towel: Premium ring spun 100% cotton",
                "Bath Towel: Soft ring spun 100% cotton yarns",
                "Face Towel: Moisture absorbent construction",
                "Hand Towel: Durable pile construction",
                "Bath Mat: 100% virgin cotton yarns",
                "All items feature ground construction"
            ],
            images: [
                "https://files.catbox.moe/r8i2vs.png",
                "https://files.catbox.moe/7h1xe5.png",
                "https://files.catbox.moe/1j2up7.png",
                "https://files.catbox.moe/wq9pux.png",
                "https://files.catbox.moe/zgl49k.png",
                "https://files.catbox.moe/ghm0r5.png",
            ]
        }
    ]

    return (
        <div style={{
            backgroundImage: `url(${background})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            position: 'relative',
            minHeight: '100vh',
        }}>
            <div style={{
                backdropFilter: 'blur(13px)',
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                padding: '2rem 0',
            }}>
                <div className='py-10 mx-auto px-4'>
                    <div className='px-4 md:px-8 lg:px-20'>
                    <h1 className='text-2xl md:text-3xl lg:text-4xl font-bold text-center mb-6 text-[#8B4513]'>Our Luxury Bath & Pool Collection</h1>
                    {products.map((product, index) => (
                        <div key={index} className='space-y-8 rounded-lg'>
                            <div className='space-y-8'>
                                <div className='w-full'>
                                    <img
                                        src={product.images[0]}
                                        alt={`${product.title} view 1`}
                                        className='w-full h-[250px] md:h-[300px] lg:h-[400px] object-contain rounded-lg'
                                    />
                                </div>

                                <div className='grid grid-cols-1 lg:grid-cols-2 gap-8'>
                                    <div className='space-y-6'>
                                        <h2 className='text-2xl md:text-3xl font-bold text-[#8B4513]'>{product.title}</h2>
                                        <p className='text-gray-700 text-sm md:text-base'>{product.description}</p>
                                        <p className='text-gray-700 text-sm md:text-base'>{product.additionalInfo}</p>
                                    </div>
                                    <img
                                        src={product.images[2]}
                                        alt={`${product.title} view 3`}
                                        className='w-full h-[250px] md:h-[300px] lg:h-[400px] object-contain rounded-lg'
                                    />
                                </div>

                                <div className='grid grid-cols-1 lg:grid-cols-3 gap-8'>
                                    <div className='space-y-4 lg:col-span-2'>
                                        <h3 className='text-lg md:text-xl font-semibold text-[#8B4513]'>Key Features</h3>
                                        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                                            {product.features.map((feature, idx) => (
                                                <div key={idx} className='flex items-center space-x-2'>
                                                    <span className='w-2 h-2 bg-[#DEB887] rounded-full'></span>
                                                    <span className='text-gray-700 text-sm md:text-base'>{feature}</span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <img
                                        src={product.images[3]}
                                        alt={`${product.title} view 4`}
                                        className='w-full h-[250px] md:h-[300px] object-contain rounded-lg'
                                    />
                                </div>

                                <div className='grid grid-cols-1 lg:grid-cols-3 gap-8'>
                                    <img
                                        src={product.images[1]}
                                        alt={`${product.title} view 5`}
                                        className='w-full h-[250px] md:h-[300px] object-contain rounded-lg'
                                    />
                                    <div className='space-y-4 lg:col-span-2'>
                                        <h3 className='text-lg md:text-xl font-semibold text-[#8B4513]'>Specifications</h3>
                                        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                                            {product.specifications.map((spec, idx) => (
                                                <div key={idx} className='flex items-center space-x-2'>
                                                    <span className='w-2 h-2 bg-[#DEB887] rounded-full'></span>
                                                    <span className='text-gray-700 text-sm md:text-base'>{spec}</span>
                                                </div>
                                            ))} 
                                        </div>
                                    </div>
                                </div>  
                            </div>
                        </div>
                    ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Bathlinen