import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useRef, useState, useEffect } from 'react';
import logo from "../images/logo/Logo@6x.png";
import logo1 from "../images/logo/Asset 2@6x.png";
import { FaBars, FaTimes } from 'react-icons/fa';

import Matttresses from '../components/Matttresses';
import Pillow from '../components/Pillow';
import Bathlinen from '../components/Bathlinen';
import Divancollection from '../components/Divancollection';
import Duevt from '../components/Deuvet';



    const Collection = () => {
        
        const [isOpen, setIsOpen] = useState(false);
        // const [selectedImage, setSelectedImage] = useState(0);
        // const constraintsRef = useRef(null);
        const drawerRef = useRef(null);

        useEffect(() => {
            const handleClickOutside = (event: MouseEvent) => {
                if (drawerRef.current && !(drawerRef.current as Node).contains(event.target as Node) && isOpen) {
                    setIsOpen(false);
                }
            };

            if (isOpen) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = 'unset';
            }

            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
                document.body.style.overflow = 'unset';
            };
        }, [isOpen]);

        const drawerVariants = {
            open: { x: 0 },
            closed: { x: "100%" }
        };

        return (
            <div className='bg-white w-full'>
                <div className='flex flex-col justify-center items-center w-full'>
                    {isOpen && (
                        <div className="fixed inset-0 bg-black/50 backdrop-blur-sm z-20" onClick={() => setIsOpen(false)} />
                    )}
                    <motion.nav 
                        className="relative flex justify-between items-center p-4 sm:p-8 w-full"
                        initial={{ y: -100 }}
                        animate={{ y: 0 }}
                        transition={{ type: "spring", stiffness: 100 }}
                    >
                        <Link to="/">
                            <motion.div 
                                className="flex items-center space-x-2"
                                whileHover={{ scale: 1.05 }}
                            >
                                <img src={logo} alt="Logo" className="h-8 sm:h-10" />
                            </motion.div>
                        </Link>
                        
                        <div className="hidden md:flex space-x-16">
                            {['Collections', 'About Us', 'Contact Us'].map((item, index) => (
                                <motion.span
                                    key={item}
                                    initial={{ opacity: 0, y: -20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: index * 0.1 }}
                                >
                                    <Link to={`/${item.toLowerCase().replace(' ', '')}`} className="text-gray-600 hover:text-[#F5DEB3] transition-all duration-300 text-lg font-medium sour-gummy-nav">{item}</Link>
                                </motion.span>
                            ))}
                        </div>
                        
                        <motion.div 
                            className="hidden md:block text-gray-600 text-lg font-medium"
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                        >
                            <a href="tel:+94712230000" className="">📞 +94 71 22 3 0000</a>
                        </motion.div>

                        <motion.button
                            className="md:hidden text-black text-xl sm:text-2xl"
                            onClick={() => setIsOpen(!isOpen)}
                            whileTap={{ scale: 0.9 }}
                        >
                            <FaBars />
                        </motion.button>
                    </motion.nav>

                    <motion.div
                        ref={drawerRef}
                        className="fixed top-0 right-0 h-full w-64 bg-white z-30 border-l border-white/20"
                        initial="closed"
                        animate={isOpen ? "open" : "closed"}
                        variants={drawerVariants}
                        drag="x"
                        dragConstraints={{ left: 0, right: 100 }}
                        dragElastic={0.2}
                        onDragEnd={(event, info) => {
                            if (info.offset.x > 100) {
                                setIsOpen(false)
                            }
                        }}
                    >
                        <div className="w-full h-full backdrop-blur-xl p-8">
                            <img src={logo1} alt="" className='mt-10'/>
                            <motion.button
                                className="absolute top-4 right-4 text-[#8B4513] text-xl hover:text-[#DEB887] transition-colors"
                                onClick={() => setIsOpen(false)}
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                            >
                                <FaTimes />
                            </motion.button>
                            <div className="flex flex-col space-y-8 mt-10">
                                {['Collections', 'About Us', 'Contact Us'].map((item, index) => (
                                    <motion.div
                                        key={item}
                                        whileHover={{ x: 10 }}
                                        whileTap={{ scale: 0.95 }}
                                    >
                                        <Link 
                                            to={`/${item.toLowerCase().replace(' ', '')}`}
                                            className="text-[#8B4513] hover:text-[#DEB887] transition-all duration-300 text-lg font-medium"
                                            onClick={() => setIsOpen(false)}
                                        >
                                            {item}
                                        </Link>
                                    </motion.div>
                                ))}
                                <div className="pt-8 border-t border-[#8B4513]">
                                    <a href="tel:+94712230000" className="">📞 +94 71 22 3 0000</a>
                                </div>
                            </div>
                        </div>
                    </motion.div>

                    <Matttresses/>
                    <Pillow/>
                    <Bathlinen/>
                    <Divancollection/>
                    <Duevt/>
                    
                    
                    
                    
                </div>
            </div>
        )
    }

    export default Collection